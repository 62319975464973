
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/ng-zorro-antd/typography/style/index.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #D70900;
@font-family: 'br_regular';
@alert-success-border-color : #52BD94;
@alert-success-bg-color: #F5FBF8;
@success-color: #317159;
@table-header-bg : #fff;
@table-header-cell-split-color : #fff;
@select-border-color : #E5EFFF;
@error-color: #A73636;
@alert-error-border-color : #D14343;
@alert-error-bg-color: #FDF4F4;
@tag-default-bg: #DCF2EA;
@tag-default-color: #317159;


/*------------heading-----------*/
// semibold_Graphik
// .typography-title(@fontSize; @fontWeight; @lineHeight; @headingColor; @headingMarginBottom;) {
//   margin-bottom: @headingMarginBottom;
//   color: @headingColor;
//   font-weight: @fontWeight;
//   font-size: @fontSize;
//   line-height: @lineHeight;
// }

.typography-title-1() {
  .typography-title(
    32px,
    600,
    48px,
    #073763,
    0px
  );
}

.typography-title-2() {
  .typography-title(
    24px,
    600,
    40px,
    #073763,
    0px
  );
}

.typography-title-3() {
  .typography-title(
    18px,
    600,
    32px,
    #073763,
    0px
  );
}


.typography-title-4() {
  .typography-title(
    16px,
    600,
    24px,
    #073763,
    0px
  );
}


/*------------Tags-----------*/
